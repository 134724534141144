/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import iot from "../../images/article2.jpg";
import chris from "../../images/chrishmal-opt.png";
import device from "../../images/device.jpg";
import IntroBanner from "../../components/IntroBanner";

const PosttestIot = (props) => {
  return (
    <Layout bodyClass="page-blog">
      <Seo
        title="Testing Narrowband IoT Deployments"
        description="iTelaSoft went through a series of engagements to test some of the European narrowband deployments and ensure the load handling capability and protocol requirements of diverse and massive sensory networks. This helped in uncovering performance and compliance bottlenecks, not only in the network, but also in the message broker infrastructure, eventually enabling a robust narrowband network for their IoT customers."
        image={iot}
      />
      <IntroBanner
        title="Testing Narrowband IoT Deployments"
        by="by Chrishmal Fernando"
        image={iot}
      />
      <div className="content">
        <div className="container pt-4 pt-md-4">
          <div className="row justify-content-start">
            <div className="col-12 col-md-12">
              <div className="content-body">
                <div className="content">
                  <p>
                    The last 12 months have been an interesting period for our
                    iTelaSoft IoT team, especially with some dominant changes
                    taking place in the mobile service providers space. Around
                    the world, many telco operators got busy deploying a new
                    service capability for IoT communication called Narrowband
                    IoT. This allows a large number of IoT devices to get
                    connected directly to application infrastructure over the
                    cellular mobile network. To manage hundreds of thousands of
                    devices and massive amount of data payloads, telco operators
                    started building next generation message hubs and device
                    management platforms.
                  </p>
                  <p>
                    iTelaSoft went through a series of engagements to test some
                    of the European narrowband deployments and ensure the load
                    handling capability and protocol requirements of diverse and
                    massive sensory networks. This helped in uncovering
                    performance and compliance bottlenecks, not only in the
                    network, but also in the message broker infrastructure,
                    eventually enabling a robust narrowband network for their
                    IoT customers.
                  </p>
                  <p>
                    {" "}
                    In order to perform the above tests and scale them out,
                    iTelaSoft developed a unique platform for narrowband testing
                    & performance monitoring. “Narrowband Test Platform” is a
                    centrally managed, but remotely deployable toolkit for
                    testing IoT data capabilities including coverage, signal
                    strength, protocols, latencies and device power consumption.
                    It is a platform that can be used for deployment testing,
                    message broker performance testing, site testing, drive
                    testing and long-term performance monitoring. Key Features
                    include,
                  </p>
                  <div className="pic-detail">
                    <div className="p-2">
                      <img alt="Device" src={device} />
                    </div>
                    <div>
                      <ul>
                        <li>Centrally managed/controlled platform</li>
                        <li>
                          Distributed test/metering devices (deployed in desired
                          locations across the network)
                        </li>
                        <li>Extensible architecture</li>
                        <li>Extensible test scenarios</li>
                        <li>Automatic test execution and reporting</li>
                      </ul>
                      <p>
                        The platform can be used in IoT Capability deployments,
                        NB-IoT (Cat-NB1) and LTE-M (Cat-M1) application
                        deployment planning, and narrowband network
                        availability/performance testing and diagnostics. Out of
                        the box, the platform supports tests on UDP, TCP, HTTP,
                        MQTT, CoAP and LWM2M. It is also capable of testing
                        secure channels over TLS or DTLS.
                      </p>
                    </div>
                  </div>
                  <p>
                    The test execution device is rack mountable and capable of
                    testing IoT data capabilities including coverage, signal
                    strength, protocols, latencies and power consumption of
                    Narrowband IoT modules. Each device houses multiple
                    narrowband modules so that it allows multiple simultaneous
                    test execution and load simulation.
                  </p>
                  <p>
                    The central control centre is a web portal for commanding
                    and monitoring all the functionalities of connected text
                    execution devices. It supports remote device provisioning
                    and management, user management, defining tests, invoking
                    tests, test scheduling and data visualization and analysis.
                  </p>
                  <p>
                    Due to the demand for more broader tests, with a wide
                    variety of radio access technologies, we recently extended
                    the platform with the ability to handle test scenarios with
                    USSD, GSM Voice, Non IP Messaging, VoLTE etc. With the
                    upgraded platform and new generation test execution devices,
                    we are able to provide a wider test scope and more
                    comprehensive network testing, not limited to narrowband
                    IoT.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid message pb-6 mt-7 mb-5">
        <div className="row text-center">
          <div className="col-12 text-center">
            <div className="title-1 pb-3">
              We offer a complete range of IoT services and solutions
            </div>
          </div>
          <div className="col-12 mt-1">
            <a
              className="btn btn-primary"
              href="/services/iot-and-devices/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Discover More
            </a>
          </div>
        </div>
      </div>
      <div className="author mt-5">
        <div className="container">
          <div className="row wrap-auther-detail">
            <div className="author-image">
              <img alt="Chrishmal" src={chris} />
            </div>
            <div className="d-flex align-items-center">
              <div className="ml-lg-3 ml-sm-0 detail">
                <h4>
                  <i>Chrishmal Fernando</i>
                </h4>
                <span>
                  <i>Telco & IoT Team</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "article1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default PosttestIot;
